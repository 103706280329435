@import '../../../SCSS/variables';

.term-page {
    ul {
        list-style: decimal;
        b {
            font-weight: 600;    color: black;
        }
        ul {
            list-style: upper-alpha;
            li {
                margin-top: 5px;
                font-size: 13px;
                margin-bottom: 5px;
            }
        }
    }
}