@import '../../../SCSS/variables';

.header {

    .MuiButton-root {
        color: white;
    }

    .left-border {
        border-left: 1px rgba(199, 199, 199, 0.62) solid;
        border-radius: 0;
    }

    .space-left {
        margin-left: 5px;
    }

    .MuiToolbar-gutters {
        padding-left: 0px;
    }
}
.drawer-root {
    .MuiDrawer-paper {
        width:280px;

    }
    
    .menu-content-root {
        position: absolute;
        top: 48px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        .MuiAvatar-root {
            background-color: $primary;
        }
       
     }
}

#usermenu{
    
    .MuiPaper-root  {
        top: 54px !important;
        border: 1px #d8d8d8 solid;
        overflow: initial !important;
    }
    .popover-content {
      
        background-size: contain;
        padding: 10px 10px 6px 10px;
        width: 190px;
        position: relative;
        border: 1px gainsboro solid;
        margin: 5px;
        border-radius: 5px;

        .arrowUp {
            position: absolute;
            right: 1px;
            top: -27px;
            font-size: 35px;
            color: #ffffff;
        }
    
    }

    .user-dropdown {
       .logout-btn {
        width: 80%;
        margin: 10px auto;
       }
       .user-info-box {
           text-align: center;
           .text-elipsis {
               max-width: 170px;
           }
       }
       strong {
            font-size: 15px;
            font-weight: 600;
            display: block;
            text-align: center;
            width: 100%;
       }
       .avatar  {
            background-color: $primaryDark;
            font-size: 14px;
            height: 40px;
            width:40px;
            font-weight: 600;
            text-shadow: 0 0 2px #3a3a3a;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)

       }
    }
}

.menu-list {
    width: 100%;
    text-align: center;

    .MuiListItemIcon-root {
        min-width: 25px !important;
    }

    .list-item {
        border-top: 1px #c1c1c1 solid;
        
        .MuiList-padding {
            padding: 0 !important;
        }
    }
    .text-ellipsis {
        max-width: 170px;
    }
} 
.login-image {
    width: 71px;
    margin-left: 40px;
    margin-bottom: -3px;
    border-radius: 5px;
}