$primary:	#228B22;
$success: #228B22;
$error: #ff3f1f;;
$primaryDark:	#097909 ;
$headerCUstomer :48px;
$adminHeader: 64px;
$sidebarWidth : 200px;

:export {
    primary: $primary;
    primaryDark:$primaryDark;
}