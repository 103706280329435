@media (max-width:960px) {
    .banner-heading {
    width: 100%;
    text-align: center;
    margin-top: 10px !important;
}}
@media (max-width:768px) {
    
    .banner {
        height: 100px;
        h1 {
            padding-top: 30px !important;
            font-size: 22px;
        }
    }
    .slider {
        margin-bottom: 50px;
        img {
            max-height: 350px;
        }
        .conntact-info {
            right: 5px;
            left: 5px;
            bottom: -50px;
            text-align: center;
            .MuiButtonBase-root {
                margin-top: 10px;
            }
        }
        
        .fixed-text {
            top: 35px;
            .full-height {
                align-items: flex-start;
            }
            h2 {
                font-size: 16px;
                margin: 0;
            }
            h3 {
                font-size: 15px;
            }


            
        }

        .prev-btn{display: none;}
        .next-btn { display: none;}
    }
   
    .container {
        padding: 0 15px;
    }
    
    .heading {
        font-size: 18px !important;
    }

    .header {
        .MuiToolbar-gutters {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .client-testimonials {
        padding: 30px 0px;
        .client-panel {
            padding: 60px 20px 20px;
            .panel-body {
                padding: 16px 29px;
            }
        }

        .prev-btn {
            left: 0px;
        }

        .next-btn {
            right: 0px;
        }
    }
}

@media( max-width: 600px) {

    img.service , .services-image {
        margin:10px  auto;
        display: block;
    }
    .slider {
        .conntact-info {
            .xs {
                justify-content: center !important;
            }
        }
    }

    .logo {
        h1 { 
            font-size: 13px; 
        }
        small {
            font-size: 10px;
            margin-top: 6px;
        }
    }

    header {
        .MuiButton-text {
            font-size: 12px;
        }
    }

}

@media (max-width: 375px) {
    .header {
        .container {
            padding: 0 5px;
        }
    }
    .logo {
        h1 { 
            font-size: 12px; 
        }
       
    }

}