@import './variables';
* {
    box-sizing: border-box;
}
body, html {
    margin:0;
    padding: 0;
    font-family: 'Lato', sans-serif !important;
    scroll-behavior: smooth;

}

.MuiTypography-root {
    font-family: 'Lato', sans-serif !important;
}

.MuiButton-root {
    text-transform: capitalize !important;
    font-family: 'Lato', sans-serif !important;
    font-size: 13px;
}

.logo {
    color:white;
    text-decoration: none;
}
.avatar  {
    background-color: $primaryDark !important;
}
.wrapper {
    margin-top: $headerCUstomer;
    background-color: #d7d7d7;
}
.customer-app {
    .RCPA-customer {
        padding-top:30px;
        padding-bottom:30px;
    }
}
.container {
    max-width: 1130px;
    margin: auto;
    width: 100%;
    padding: 0 15px;
}
h1, h2, h3 , h4, h5, h6, .heading {
    font-family: 'Archivo Black', cursive!important;
}
a {
    color: $primary;
}

.heading {
    font-size: 27px !important;
    color:$primary;
}
.logo {
    cursor: pointer;
    h1{
        font-size: 16px;
        line-height: 0;
    }
    small {
        text-align: center;
        text-transform: uppercase;
        font-size: 11px;
        display: block;
        margin-top: 11px;
        color: rgba(234, 234, 234, 0.72);
        letter-spacing: 1px;
    }
}

.text-center {
    text-align: center;
}

.list-view {
    list-style: none;
    margin-top: 10px;
    .list-view {
        list-style: circle;
    }
    li {
        margin-bottom: 10px;
    }
}

.error-field {
    text-align: right;
}
.hide {
    visibility: hidden;
}
.MuiList-dense {
        padding: 0 !important;
    }

.error-field-root {
    height: 20px;
}

.dropdrown-list-custom {
    max-height: 100px !important;
    overflow: auto;
    max-width: 100% !important;
    z-index: 99 !important;
}

.primary-text {
    .MuiInputLabel-root , input{
        color: $primary;
        font-weight: 600;
    }
    
}
p{
    text-align: justify;
}
.banner {
    background-size: cover;
}