@import '../../../SCSS/variables';
.field {
    // min-height: 105px;
}
.heading-small {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 10px !important;
    .MuiButtonBase-root {
        color: $primary;
        text-decoration: underline;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
    }
}
.verify-form {
    // border: 1px #bfbbbb solid;
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
}
.padding{ 
    padding: 16px 30px !important; 
}
.add-customer {
    margin: auto auto 50px auto;
    border-radius: 10px;
    overflow: initial !important;

    .loading-button {
        margin-top: 30px;
    }
    
    .MuiTypography-body2 {
        font-size: 12px;
        font-weight: bolder !important;
        
    }
    .MuiStepper-root {
        background-color: #ececec;
        border-bottom: 1px #cecece solid;
        margin-bottom: 10px;
    }
    .MuiSvgIcon-root {
        height: 1.5em;
        width: 1.5em;  
    }
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        margin-top: 5px;
    }

}

.margin-top {
    margin-top: 25px !important;
}
.view-vehicle {

    margin-bottom: 10px;
    border: 1px #d0d0d0 solid;

    .MuiFormControlLabel-root {
        position: absolute;
        right: 40px;
        top: 10px;
        .MuiButtonBase-root {
            color: $error;
        }
    }
    .MuiExpansionPanelSummary-root.Mui-expanded {
        min-height: 48px;
        background-color: #eaeaea;
    }

    &.Mui-expanded {
        .MuiCollapse-container{
            border-top: 1px solid rgb(212, 212, 212);
        }
    }

    .title {
        b { 
            display: inline-block;
            padding: 0 5px;
        }
    }

    .border-box {

        border: 1px #a5a5a5 solid;
        border-radius: 5px;
        padding: 30px;
    }
}