@import '../../../SCSS/variables';
.our-services {
    background-color: #d6d6d6;

    .MuiExpansionPanel-root{
        margin-top: 10px;
        border: 1px #b7b7b7 solid;
        border-radius: 10px;
        
        .heading {
            font-size: 14px !important;
        }
        p, li {
            font-size: 14px;
            line-height: 20px;
        }
        .list-view {
            list-style: disc;
        }
        // margin-top: -1px;
        
        &:before {
            background-color: $primary;
            content: none;
        }
        &.Mui-expanded {
            margin:10px 0 0 0;
        }

        .MuiExpansionPanelSummary-root{
            &.Mui-expanded {
                min-height: 48px;
                border-bottom: 1px #bbbbbb solid;
            }
        }
      
        .MuiExpansionPanelSummary-content{
            color:$primary;
           
            .MuiTypography-root {
                font-weight: bold;
                font-size: 15px;
                font-family: 'Lato', sans-serif !important;
            }
            &.Mui-expanded {
                margin:0;
            }
        }
    }
    .button-list {
        button {
           width: 100%;
        }
    }
}