.footer {
    background-color: #333333;
    color: white;
    padding-top: 30px;
    font-size: 13px;

    .lowercase {
        text-transform: lowercase !important;
    }
    .MuiButton-label {
        justify-content: left;
    }
    .MuiButton-root  {
        color: white;
        font-size: 13px;
        // text-transform: lowercase !important;
    }
    h3{
        font-size: 18px;
    margin-bottom: 15px;
    border-bottom: 1px white solid;
    display: block;
    }

    .copyright {
        margin-top: 15px;
        text-align: center;
        background-color: #2d2d2d;
        border-top: 1px #6d6d6d solid;
        font-size: 11px;
        padding: 8px;
    }
}

.login-footer{
    width: 100px;
    border-radius: 5px;
    margin-top: 10px;
}