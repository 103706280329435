@import '../../../SCSS/variables';

.client-testimonials {
    padding: 30px 50px;
    position: relative;


    .heading {
        text-align: center;
    }
    .contaner {
        position: relative;  
    }
    .client-panel {
        padding:60px 10px  10px;
        .panel-body {

            border: 1px #d4d4d4 solid;
            padding: 15px;
            border-radius: 20px;
            background-color: #e8e8e8;
            box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
            
            img {
                max-height: 60px;
                margin: -45px auto auto;
            }
            p {
                font-size: 14px;
                text-align: justify;
                min-height: 90px;
            }
            .client-info{
                text-align: center;
                strong {
                    display: block;
                    color:$primary
                }
            }
        }
    }

    .next-btn {
        position: absolute;
        z-index: 3;
        top:50%;
        right: -40px;
        color: white;
        margin-top: -10px;
    }
    .prev-btn {
        position: absolute;
        z-index: 3;
        top:50%;
        left:-40px;
        color: white;
        margin-top: -10px;
    }

    
}
