@import '../../../SCSS/variables';

.history {
    position: relative;
    padding: 50px 0;
    .image-card {
        position: relative;
        z-index: 2;
    }
    .heading-top {
        position: absolute;
        top: -46px;
    }
    .circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $primary;
        position: absolute;
        z-index: 1;
        top: 20px;
    }
    .item-card {
        position: relative;
    }
    .circle-line {
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $primary;
        top: 28px;
    }

    .item-card:nth-child(odd) {
        padding-right: 50px;
        &:before {
            content: '';
            position: absolute;
            right: 0;
            left: auto;
            width: 2px;
            height: 100%;
            background-color: $primary;
        }   
        
        .circle {
            right: -10px;
        }
        .circle-line {
            right: -2px;
        }
    }
    .item-card:nth-child(even) {
        padding-left: 50px;
        margin-top: 200px;
        &:before {
            content: '';
            position: absolute;
            right: auto;
            left: -2px;
            width: 2px;
            height: 100%;
            background-color: $primary;
        }   
        .circle {
            left: -12px;

        }
        .circle-line {
            left: -2px;
        }
    }
    
}