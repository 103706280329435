.banner {
    height: 230px;
    position: relative;

    .overlay {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top:0;
        background-color: rgba(0,0,0 ,0.58);
    }

    .container{
        position: relative;
        z-index: 2;
        h1 {
            color: white;
            margin: 0;
            text-align: center;
            padding-top: 74px;
        }
    }
}