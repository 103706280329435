.section {
    padding: 50px 0;
    img {
        max-width: 90%;
    }
}
.gray {
    background-color: #e2e2e2;
    margin-top: -6px;
    border-top: 1px #cecece solid;
    border-bottom: 1px #cecece solid;
}
img.service {
    max-height: 270px;
    display: block;
    margin: 0 0 auto auto;
}
.services-image {
    max-height: 200px;
}

.border-bottom {
    border-bottom: 1px #cecece solid;
}
.image-box {
    height: 184px;
    margin: auto;
    width: 89%;
    background-size: 100%;
    background-position-y: center;
}

.padding-v {
    padding: 20px 0 !important;
}
.image-group {
    img {
        width: 100%;
        border-radius: 10px;
        max-height: 101px;
        max-width: 230px;
        display: block;
        margin: auto;
    }
}

@media(max-width:960px) {
.image-section {
    .row-1 {
        max-width: 50% !important;
        flex-basis:50%;
        margin-bottom: 15px;
    }
}
}