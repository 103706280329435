@import '../../../SCSS/variables';

.contact-us {
    button {
        font-weight: bold;
        text-transform: lowercase !important;
    }

    .border-left {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: -10px;
            right: 0;
            top: 53px;
            bottom: 19px;
            background-color: #b7b7b7;
            width: 1px;
        }
    }
}
.location-map {
    border: 2px $primary solid;
    margin-top: 50px;
    iframe {
        width: 100%;
        height: 290px;
        border: 0;
    }
}