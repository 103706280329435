@import '../../../SCSS/variables';
.image-card {
    border: 1px #dadada solid;
    transition-duration: 500ms;

    .date-tag {
        background-color: $primary;
        position: absolute;
        z-index: 1;
        color: white;
        padding: 5px;
        font-size: 13px;
    }
    &:hover {
        border: 1px $primary solid;
        transition-duration: 500ms;
    }
    h3 {
        color: $primary;
        font-weight: 600;
    }
    .MuiCardMedia-root {
        height: 300px;
    }
}